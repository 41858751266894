<template>
  <div class="virtualsimulationexperiment">
    <div class="head-contain">
      <!-- <div class="head-contain-left"></div> -->
      <div class="head-contain-center">
        <div class="title">
          <div class="title-icon">
            <el-image class="elimage" :src="image" />
          </div>
          <div class="title-text">虚拟仿真实验</div>
        </div>
      </div>
      <div class="head-contain-right">
        <div class="more-contain">
          <div class="more" @click="tomore">
            <div class="more-title">更多</div>
            <div class="more-icon">
              <el-image class="elimage" :src="moreicon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-item" v-for="(item, index) in  vrlist " :key="index">
        <div class="china_class_main">
          <template>
            <div class="image-contain" @click="todetail(item.id)" v-if="item.cover">
              <el-image class="elimage" :src="item.cover" />
            </div>
            <div class="image-contain1" @click="todetail(item.id)" v-else>
              <el-image class="elimage" :src="image11" />
            </div>
            <div
              :class="{ 'china_class': item.level == '国家级', 'china_class_1': item.level == '校级', 'china_class_2': item.level == '省级' }">
              <span>{{ item.level }}</span>
            </div>
          </template>
        </div>
        <div class="bottomcontain">
          <div class="title1">{{ item.name }}</div>
          <div class="title2">
            <div class="name">{{ item.leader_name }}</div>
            <div class="rightimage">
              <el-image class="elimage" :src="image3"></el-image>
              <span class="text">{{ item.join_count }}人参与</span>
            </div>
          </div>
        </div>
      </div>
      <div class="empty-item" v-for="( item, index ) in  emptylist " :key="index + '_dd332jjk_545kka'"></div>
    </div>
  </div>
</template>

<script>
import { getVRList } from "@/api/vr";
export default {
  name: "Virtualsimulationexperiment",
  components: {},
  data() {
    return {
      vrlist: [],
      emptylist: [],
      image: require("@/assets/new/虚拟仿真.png"),
      moreicon: require("@/assets/new/right.png"),
      testimage: require("@/assets/test.png"),
      image3: require("@/assets/new/people.png"),
      image01: require("@/assets/new2/1.jpg"),
      image02: require("@/assets/new2/2.jpg"),
      image03: require("@/assets/new2/3.jpg"),
      image04: require("@/assets/new2/4.jpg"),
      image05: require("@/assets/new2/5.jpg"),
      image06: require("@/assets/new2/6.jpg"),
      image07: require("@/assets/new2/7.jpg"),
      image08: require("@/assets/new2/8.jpg"),
      image09: require("@/assets/new2/9.jpg"),
      image10: require("@/assets/new2/10.jpg"),
      image11: require("@/assets/vr.png"),
    };
  },
  computed: {},
  mounted() {
    this.getVRList({
      per_page: 12,
      page: 1,
      is_show_by_rules: 1,
    });
  },
  methods: {
    randomimage() {
      let items = [
        this.image01,
        this.image02,
        this.image03,
        this.image04,
        this.image05,
        this.image06,
        this.image07,
        this.image08,
      ];
      let item = items[Math.floor(Math.random() * items.length)];
      return item;
    },
    getVRList(params) {
      this.vrlist = [];
      this.emptylist = [];
      getVRList(params)
        .then((response) => {
          if (response.data && response.data.data) {
            let itemlist = response.data.data;
            for (let i = 0; i < 12; i++) {
              if (itemlist[i]) {
                this.vrlist.push(itemlist[i]);
              } else {
                this.emptylist.push({});
              }
            }
          }
        })
        .catch((error) => { });
    },
    tomore() {
      window.localStorage.setItem("menuId", 1);
      this.$store.dispatch("setmenuid", 1);
      this.$router.push({
        path: "/home/virtualsimulationexperiment/mainpage",
      });
    },

    todetail(id) {
      window.localStorage.setItem("menuId", 1);
      this.$store.dispatch("setmenuid", 1);
      this.$router.push({
        path: "/home/virtualsimulationexperiment/experimentdetails",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .virtualsimulationexperiment {
    //   border: 1px solid red;
    // background: #f7f7f7;
    padding-left: 10px;
    padding-right: 10px;

    width: 95%;
    height: 100%;

    .head-contain {
      // border: 1px solid red;
      margin-top: 10px;
      // margin-bottom: 30px;
      padding-bottom: 5px;
      // padding-left: 60px;
      height: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-top: 10px;
      margin-bottom: 10px;

      .head-contain-left {
        // border: 1px solid red;
        height: 100%;
        width: 64px;
      }

      .head-contain-center {
        // border: 1px solid red;
        height: 100%;

        //   width: 130px;
        .title {
          // border: 1px solid red;
          // margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          .title-icon {
            margin-right: 10px;
            width: 25px;
            height: 25px;

            .elimage {
              width: 100%;
              height: 100%;
            }
          }

          .title-text {
            // margin-left: 10px;
            font-size: 20px;

            font-weight: bold;
            color: #000000;
          }
        }
      }

      .head-contain-right {
        //   border: 1px solid red;
        height: 100%;

        //   width: 100px;
        .more-contain {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .more {
            width: 64px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #ededed;
            border-radius: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            cursor: pointer;

            .more-title {
              margin-left: 10px;
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #666666;
            }

            .more-icon {
              margin-right: 10px;

              .elimage {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }
    }

    .content {
      // border: 1px solid rgb(0, 255, 0);
      // height: 365px;
      margin-top: 20px;

      // display: flex;
      // justify-content: flex-start;
      // align-items: center;
      .content-item {
        border: 1px solid transparent;
        height: 235px;
        width: 330px;
        flex-shrink: 1;
        cursor: pointer;
        margin: 0px 5px;
        width: calc((100% - 25px) / 2);
        float: left;

        // box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 15%);
        &:hover {
          // border: 1px solid rgb(9, 235, 178);
        }

        .china_class_main {
          position: relative;

          .image-contain {
            width: 100%;
            height: 160px;

            .elimage {
              display: inline-block;
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
              -webkit-transition-property: transform;
              transition-property: transform;
              -webkit-transform: translateZ(0);
              transform: translateZ(0);
              box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            }

            .elimage:hover,
            .elimage:focus,
            .elimage:active {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }

            .elimage {
              width: 100%;
              height: 100%;
            }
          }
          .image-contain1 {
            width: 100%;
            height: 160px;
            background: #daebfc;
            text-align: center;

            .elimage {
              display: inline-block;
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
              -webkit-transition-property: transform;
              transition-property: transform;
              -webkit-transform: translateZ(0);
              transform: translateZ(0);
              box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            }

            .elimage:hover,
            .elimage:focus,
            .elimage:active {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }

            .elimage {
              width: 70%;
              height: 100%;
            }
          }

          .china_class {
            position: absolute;
            top: 0px;
            right: 0;
            background: #ff0c0c;
            font-size: 12px;
            color: #fff;
            width: 50px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            border-radius: 0px 0px 0px 14px;
          }

          .china_class_1 {
            position: absolute;
            top: 0px;
            right: 0;
            background: #4980fe;
            font-size: 12px;
            color: #fff;
            width: 50px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            border-radius: 0px 0px 0px 14px;
          }

          .china_class_2 {
            position: absolute;
            top: 0px;
            right: 0;
            background: #FBC248;
            font-size: 12px;
            color: #fff;
            width: 50px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            border-radius: 0px 0px 0px 14px;
          }
        }

        .bottomcontain {
          // border: 1px solid red;
          padding: 10px 0px;
          background: #ffffff;

          .title1 {
            margin-left: 10px;
            text-align: left;
            font-size: 14px;
            text-overflow: ellipsis;
            font-weight: 500;
            color: #222222;
            width: 90%;
            white-space: nowrap;
            overflow: hidden;
          }

          .title2 {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .name {
              margin-left: 10px;
              font-size: 12px;
              font-weight: 400;
              color: #666666;
            }

            .rightimage {
              //  border: 1px solid red;
              min-width: 88px;
              // height: 24px;
              display: flex;
              justify-content: flex-end;
              align-items: center;

              .elimage {
                width: 16px;
                height: 16px;
              }

              .text {
                margin-right: 10px;
                margin-left: 4px;
                font-size: 12px;
                text-align: left;
                color: #999;
              }
            }
          }
        }
      }

      .empty-item {
        // border: 1px solid red;
        height: 100%;
        width: 330px;
        margin: 0px 10px;

        flex-shrink: 1;
      }
    }
  }
}

@media screen and (min-width: 640px) and (max-width: 1200px) {
  .virtualsimulationexperiment {
    //   border: 1px solid red;
    // background: #f7f7f7;
    padding-left: 10px;
    padding-right: 10px;

    width: 1760px;
    height: 100%;

    .head-contain {
      // border: 1px solid red;
      margin-top: 10px;
      // margin-bottom: 30px;
      padding-bottom: 5px;
      // padding-left: 60px;
      height: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-top: 10px;
      margin-bottom: 10px;

      .head-contain-left {
        // border: 1px solid red;
        height: 100%;
        width: 64px;
      }

      .head-contain-center {
        // border: 1px solid red;
        height: 100%;

        //   width: 130px;
        .title {
          // border: 1px solid red;
          // margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          .title-icon {
            margin-right: 10px;
            width: 25px;
            height: 25px;

            .elimage {
              width: 100%;
              height: 100%;
            }
          }

          .title-text {
            // margin-left: 10px;
            font-size: 20px;

            font-weight: bold;
            color: #000000;
          }
        }
      }

      .head-contain-right {
        //   border: 1px solid red;
        height: 100%;

        //   width: 100px;
        .more-contain {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .more {
            width: 64px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #ededed;
            border-radius: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            cursor: pointer;

            .more-title {
              margin-left: 10px;
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #666666;
            }

            .more-icon {
              margin-right: 10px;

              .elimage {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }
    }

    .content {
      // border: 1px solid rgb(0, 255, 0);
      // height: 365px;
      margin-top: 20px;

      // display: flex;
      // justify-content: flex-start;
      // align-items: center;
      .content-item {
        border: 1px solid transparent;
        height: 235px;
        width: 330px;
        flex-shrink: 1;
        cursor: pointer;
        margin: 0px 5px;
        width: calc((100% - 48px) / 4);
        float: left;

        // box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 15%);
        &:hover {
          // border: 1px solid rgb(9, 235, 178);
        }

        .china_class_main {
          position: relative;

          .image-contain {
            width: 100%;
            height: 160px;

            .elimage {
              display: inline-block;
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
              -webkit-transition-property: transform;
              transition-property: transform;
              -webkit-transform: translateZ(0);
              transform: translateZ(0);
              box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            }

            .elimage:hover,
            .elimage:focus,
            .elimage:active {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }

            .elimage {
              width: 100%;
              height: 100%;
            }
          }
          .image-contain1 {
            width: 100%;
            height: 160px;
            background: #daebfc;
            text-align: center;

            .elimage {
              display: inline-block;
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
              -webkit-transition-property: transform;
              transition-property: transform;
              -webkit-transform: translateZ(0);
              transform: translateZ(0);
              box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            }

            .elimage:hover,
            .elimage:focus,
            .elimage:active {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }

            .elimage {
              width: 70%;
              height: 100%;
            }
          }

          .china_class {
            position: absolute;
            top: 0px;
            right: 0;
            background: #ff0c0c;
            font-size: 12px;
            color: #fff;
            width: 50px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            border-radius: 0px 0px 0px 14px;
          }

          .china_class_1 {
            position: absolute;
            top: 0px;
            right: 0;
            background: #4980fe;
            font-size: 12px;
            color: #fff;
            width: 50px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            border-radius: 0px 0px 0px 14px;
          }

          .china_class_2 {
            position: absolute;
            top: 0px;
            right: 0;
            background: #FBC248;
            font-size: 12px;
            color: #fff;
            width: 50px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            border-radius: 0px 0px 0px 14px;
          }
        }

        .bottomcontain {
          // border: 1px solid red;
          padding: 10px 0px;
          background: #ffffff;

          .title1 {
            margin-left: 10px;
            text-align: left;
            font-size: 14px;
            text-overflow: ellipsis;
            font-weight: 500;
            color: #222222;
            width: 90%;
            white-space: nowrap;
            overflow: hidden;
          }

          .title2 {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .name {
              margin-left: 10px;
              font-size: 12px;
              font-weight: 400;
              color: #666666;
            }

            .rightimage {
              //  border: 1px solid red;
              min-width: 130px;
              // height: 24px;
              display: flex;
              justify-content: flex-end;
              align-items: center;

              .elimage {
                width: 16px;
                height: 16px;
              }

              .text {
                margin-right: 10px;
                margin-left: 4px;
                font-size: 12px;
                text-align: left;
                color: #999;
              }
            }
          }
        }
      }

      .empty-item {
        // border: 1px solid red;
        height: 100%;
        width: 330px;
        margin: 0px 10px;

        flex-shrink: 1;
      }
    }
  }
}


@media screen and (min-width: 1200px) {
  .virtualsimulationexperiment {
    //   border: 1px solid red;
    // background: #f7f7f7;
    padding-left: 50px;
    padding-right: 50px;

    width: 1760px;
    height: 100%;

    .head-contain {
      // border: 1px solid red;
      margin-top: 10px;
      // margin-bottom: 30px;
      padding-bottom: 5px;
      // padding-left: 60px;
      height: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-top: 10px;
      margin-bottom: 10px;

      .head-contain-left {
        // border: 1px solid red;
        height: 100%;
        width: 64px;
      }

      .head-contain-center {
        // border: 1px solid red;
        height: 100%;

        //   width: 130px;
        .title {
          // border: 1px solid red;
          // margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          .title-icon {
            margin-right: 10px;
            width: 32px;
            height: 32px;

            .elimage {
              width: 100%;
              height: 100%;
            }
          }

          .title-text {
            // margin-left: 10px;
            font-size: 26px;

            font-weight: 500;
            color: #000000;
          }
        }
      }

      .head-contain-right {
        //   border: 1px solid red;
        height: 100%;

        //   width: 100px;
        .more-contain {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .more {
            width: 64px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #ededed;
            border-radius: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            cursor: pointer;

            .more-title {
              margin-left: 10px;
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #666666;
            }

            .more-icon {
              margin-right: 10px;

              .elimage {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }
    }

    .content {
      // border: 1px solid rgb(0, 255, 0);
      // height: 365px;
      margin-top: 20px;

      // display: flex;
      // justify-content: flex-start;
      // align-items: center;
      .content-item {
        border: 1px solid transparent;
        height: 235px;
        width: 330px;
        flex-shrink: 1;
        cursor: pointer;
        margin: 0px 10px;
        width: calc((100% - 135px) / 6);
        float: left;
        overflow: hidden;

        // box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 15%);
        &:hover {
          // border: 1px solid rgb(9, 235, 178);
        }

        .china_class_main {
          position: relative;

          .image-contain {
            width: 100%;
            height: 160px;

            .elimage {
              display: inline-block;
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
              -webkit-transition-property: transform;
              transition-property: transform;
              -webkit-transform: translateZ(0);
              transform: translateZ(0);
              box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            }

            .elimage:hover,
            .elimage:focus,
            .elimage:active {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }

            .elimage {
              width: 100%;
              height: 100%;
            }
          }
          .image-contain1 {
            width: 100%;
            height: 160px;
            background: #daebfc;
            text-align: center;

            .elimage {
              display: inline-block;
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
              -webkit-transition-property: transform;
              transition-property: transform;
              -webkit-transform: translateZ(0);
              transform: translateZ(0);
              box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            }

            .elimage:hover,
            .elimage:focus,
            .elimage:active {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }

            .elimage {
              width: 70%;
              height: 100%;
            }
          }

          .china_class {
            position: absolute;
            top: 0px;
            right: 0;
            background: #ff0c0c;
            font-size: 12px;
            color: #fff;
            width: 50px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            border-radius: 0px 0px 0px 14px;
          }

          .china_class_1 {
            position: absolute;
            top: 0px;
            right: 0;
            background: #4980fe;
            font-size: 12px;
            color: #fff;
            width: 50px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            border-radius: 0px 0px 0px 14px;
          }

          .china_class_2 {
            position: absolute;
            top: 0px;
            right: 0;
            background: #FBC248;
            font-size: 12px;
            color: #fff;
            width: 50px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            border-radius: 0px 0px 0px 14px;
          }
        }

        .bottomcontain {
          // border: 1px solid red;
          padding: 10px 0px;
          background: #ffffff;

          .title1 {
            margin-left: 10px;
            text-align: left;
            font-size: 14px;
            text-overflow: ellipsis;
            font-weight: 500;
            color: #222222;
            width: 90%;
            white-space: nowrap;
            overflow: hidden;
          }

          .title2 {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .name {
              margin-left: 10px;
              font-size: 12px;
              font-weight: 400;
              color: #666666;
            }

            .rightimage {
              //  border: 1px solid red;
              min-width: 130px;
              // height: 24px;
              display: flex;
              justify-content: flex-end;
              align-items: center;

              .elimage {
                width: 16px;
                height: 16px;
              }

              .text {
                margin-right: 10px;
                margin-left: 4px;
                font-size: 12px;
                text-align: left;
                color: #999;
              }
            }
          }
        }
      }

      .empty-item {
        // border: 1px solid red;
        height: 100%;
        width: 330px;
        margin: 0px 10px;

        flex-shrink: 1;
      }
    }
  }
}
</style>

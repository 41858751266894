<template>
  <div class="notice">
    <!-- notice -->
    <!-- <div class="notice-common notice-left">
      <div class="notice-left-head">
        <div class="notice-left-head-title">通知公告</div>
        <div class="notice-left-head-more" @click="tomore">
          <div class="more-title">更多</div>
          <div class="more-icon">
            <el-image class="elimage" :src="image1" />
          </div>
        </div>
      </div>
      <div class="notice-left-line"></div>
      <div class="notice-left-content">
        <div class="conten-item" v-for="(item, index) in notice" :key="index" @click="todetail0(item.id)">
          <div class="conten-item-title">
            <div class="conten-item-title-title">
              <el-image v-if="index == 0" class="elimage" :src="newicon" />
              <div class="title">{{ item.title }}</div>
            </div>
          </div>
          <div class="conten-item-text">
            {{ item.sub_title }}
          </div>
          <div class="conten-item-title-time">
            <i class="el-icon-stopwatch"></i>
            {{ item.updated_at }}
          </div>
        </div>
      </div>
    </div> -->
    <!-- news -->
    <!-- <div class="notice-common notice-center">
      <div class="notice-center-head">
        <div class="notice-center-head-title">新闻资讯</div>
        <div class="notice-center-head-more" @click="tonews">
          <div class="more-title">更多</div>
          <div class="more-icon">
            <el-image class="elimage" :src="image1" />
          </div>
        </div>
      </div>
      <div class="notice-center-line"></div>
      <div class="notice-center-content">
        <div class="conten-item" v-for="(item, index) in news" :key="index" @click="todetail(item.id)">
          <div class="conten-item-icon">
            <el-image class="elimage" :src="item.cover" />
          </div>
          <div class="conten-item-right">
            <div class="conten-item-right-head">
              <div class="head-title">{{ item.title }}</div>
            </div>
            <div class="conten-item-right-content">
              {{ item.sub_title }}
            </div>
            <div class="head-time">
              <i class="el-icon-stopwatch"></i>
              {{ item.updated_at }}
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="notice-common notice-right" @click="tourl">
      <div class="notice-right-top">
        <!-- <span>后台管理端入口</span> -->
        <!-- <el-image class="elimage" :src="image04" @click="tourl" /> -->
      </div>
      <!-- <div class="notice-right-bottom">
        <el-image class="elimage" :src="image05" @click="tonews" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
// import { getNewsList } from "@/api/news";
// import { getNoticeList } from "@/api/notice";
import dayjs from "dayjs";
const request_base_path = require('@/utils/base_url')
export default {
  name: "Notice",
  components: {},
  data() {
    return {
      notice: [],
      news: [
        // {
        //   cover: '',
        //   id: 2,
        //   sub_title: 'fdfdfdfd',
        //   title: 'hgghhghg',
        // },
      ],
      // newicon: require("@/assets/new/new_icon.png"),
      // moreicon: require("@/assets/more.png"),
      // image01: require("@/assets/护理.png"),
      // image02: require("@/assets/植物.png"),
      // image03: require("@/assets/植物2.png"),
      image04: require("@/assets/banner.png"),
      // image05: require("@/assets/new/pic55.jpg"),
      // image1: require("@/assets/new/right.png"),
    };
  },
  computed: {},
  mounted() {
    // this.getNoticeList({
    //   per_page: 4,
    //   page: 1,
    //   is_show_by_rules: 1,
    // });
    // this.getNewsList({
    //   per_page: 4,
    //   page: 1,
    //   is_show_by_rules: 1,
    // });
  },
  methods: {
    todetail0(id) {
      window.localStorage.setItem("menuId", 5);
      this.$store.dispatch("setmenuid", 5);
      this.$router.push({
        path: "/home/announcement/notifydetails",
        query: {
          id: id,
        },
      });
    },

    todetail(id) {
      window.localStorage.setItem("menuId", 6);
      this.$store.dispatch("setmenuid", 6);
      this.$router.push({
        path: "/home/news/newsdetails",
        query: {
          id: id,
        },
      });
    },
    tourl() {
      let token = getToken();
      if (token) {
        if (process.env.NODE_ENV == 'development') {
          let href = "http://localhost:9527/login"; // 本地
          let params = "?schoolid=1&token=" + getToken();
          window.open(href + params, "_blank"); // 拼接外部网址
          // location.href = request_base_path.dev_url + '/cas-logout'
          // this.$router.push({ path: "/" });
        } else if (process.env.NODE_ENV == 'production') {
          let href = "https://shijian.hzau.edu.cn/teacher-admin/#/login"; // 线上
          let params = "?schoolid=1&token=" + getToken();
          window.open(href + params, "_blank"); // 拼接外部网址
          // location.href = request_base_path.pro_url + '/cas-logout'  //正式服需要的
          //  this.$router.push({ path: "/" });//测试服需要的
        }
      } else {
        window.open("https://shijian.hzau.edu.cn/teacher-admin/#/login", "_blank"); // 拼接外部网址
      }
    },
    randomimage() {
      let items = [this.image01, this.image02, this.image03];
      let item = items[Math.floor(Math.random() * items.length)];
      return item;
    },

    // getNoticeList(params) {
    //   this.notice = [];
    //   getNoticeList(params)
    //     .then((response) => {
    //       if (response.data.data && response.data.data.length > 0) {
    //         response.data.data.forEach((item) => {
    //           this.notice.push({
    //             id: item.id,
    //             sub_title: item.sub_title,
    //             title: item.title,
    //             updated_at: dayjs.unix(item.updated_at).format("YYYY-MM-DD"),
    //           });
    //         });
    //       }
    //     })
    //     .catch((error) => { });
    // },

    // getNewsList(params) {
    //   this.news = [];
    //   getNewsList(params)
    //     .then((response) => {
    //       if (response.data.data && response.data.data.length > 0) {
    //         response.data.data.forEach((item) => {
    //           this.news.push({
    //             cover: item.cover,
    //             id: item.id,
    //             sub_title: item.sub_title,
    //             title: item.title,
    //             updated_at: dayjs.unix(item.updated_at).format("YYYY-MM-DD"),
    //           });
    //         });
    //       }
    //     })
    //     .catch((error) => { });
    // },
    tomore() {
      window.localStorage.setItem("menuId", 5);
      this.$store.dispatch("setmenuid", 5);
      this.$router.push({
        path: "/home/announcement/mainpage",
      });
    },
    tonews() {
      window.localStorage.setItem("menuId", 6);
      this.$store.dispatch("setmenuid", 6);
      this.$router.push({
        path: "/home/news/mainpage",
      });
    },
  },
};
</script>
<style scoped lang="scss">







.notice {
  padding-left: 50px;
  padding-right: 50px;
  width: 1760px;
  height: 100%;
  // background: #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  // position: fixed;
  //   right: 0px;
  //   z-index: 99;
  //   width: 30px;
  //     height: 150px;
  //     background: #6192e7;
  //     text-align: center;
  //     top: 560px;
  //     text-align: center;
  //     color: #ffffff;
  //     font-weight: bold;
  //       border-radius: 5px;
  //       padding: 15px;
  //       box-shadow: 1px 2px 3px 2px #6192e7;
      

  .notice-common {
    // border: 1px solid red;
    // width: 560px;
    // flex: 1;
    // flex-shrink: 1;
    // height: 400px;
    // background: #ffffff;
    // border-radius: 4px;
    // width: calc((100% - 100px) / 3);
    // float: left;
    // height: 470px;
  }

  .notice-left {
    // border: 1px solid red;
    background: #f9f9f9;

    .notice-left-head {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      //   border: 1px solid red;
      .notice-left-head-title {
        margin-left: 19px;
        font-size: 25px;

        font-weight: 400;
        color: #000000;
      }

      .notice-left-head-more {
        margin-right: 10px;
        width: 64px;
        height: 28px;
        background: #ffffff;
        border: 1px solid #ededed;
        border-radius: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        user-select: none;

        cursor: pointer;

        .more-title {
          margin-left: 10px;
          font-size: 12px;

          font-weight: 500;
          color: #666666;
        }

        .more-icon {
          // border: 1px solid red;
          margin-right: 10px;

          .elimage {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .notice-left-line {
      margin-top: 10px;
      margin-bottom: 10px;
      border: 1px solid #cccccc;
    }

    .notice-left-content {
      width: 100%;

      .conten-item {

        // margin-bottom: 10px;
        .conten-item-title {
          padding-left: 10px;
          padding-right: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .conten-item-title-title {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .elimage {
              width: 30px;
              height: 30px;
              margin-right: 10px;
              float: left;
            }

            .title {
              font-size: 16px;
              font-weight: 500;
              color: #000;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .conten-item-title-title :hover {
            color: #3d84ff;
          }
        }

        .conten-item-title-time {
          font-size: 12px;
          font-weight: 400;
          color: #b29873;
          text-align: right;
          margin-right: 15px;
        }

        .conten-item-text {
          margin-top: 5px;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 14px;

          font-weight: 400;
          color: #777;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 28px;
          cursor: pointer;

        }

        .conten-item-text:hover {
          color: #b29873;
        }
      }
    }
  }

  .notice-center {
    // border: 1px solid red;
    background: #f9f9f9;
    margin-left: 40px;

    .notice-center-head {
      margin-top: 10px;
      margin-bottom: 10px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      //   border: 1px solid red;
      .notice-center-head-title {
        margin-left: 19px;
        font-size: 25px;

        font-weight: 400;
        color: #000000;
      }

      .notice-center-head-more {
        margin-right: 10px;
        width: 64px;
        height: 28px;
        background: #ffffff;
        border: 1px solid #ededed;
        border-radius: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        user-select: none;
        cursor: pointer;

        .more-title {
          margin-left: 10px;
          font-size: 12px;

          font-weight: 500;
          color: #666666;
        }

        .more-icon {
          margin-right: 10px;

          .elimage {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .notice-center-line {
      margin-top: 10px;
      margin-bottom: 10px;
      border: 1px solid #cccccc;
    }

    .notice-center-content {
      .conten-item {
        // margin-top: 10px;
        // display: flex;
        // justify-content: flex-start;
        // align-items: center;
        // padding-left: 18px;
        // padding-right: 20px;
        // cursor: pointer;
        width: calc(100% - 20px);
        overflow: hidden;
        // margin-bottom: 10px;
        padding: 0px 10px 0px 10px;

        .conten-item-icon {
          // border: 1px solid red;
          // height: 71px;
          // flex-basis: 144px;
          float: left;
          width: 30%;
          text-align: center;

          .elimage {
            height: 71px;
            width: 100%;
          }
        }

        .conten-item-right {
          // border: 1px solid red;
          // margin-left: 6px;
          // height: 71px;
          // flex-grow: 1;
          // flex-shrink: 1;
          // display: flex;
          // flex-direction: column;
          // justify-content: flex-start;
          // align-items: space-between;
          float: left;
          width: calc(100% - 30% - 10px);
          margin-left: 10px;

          .conten-item-right-head {
            // border: 1px solid red;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .head-title {
              // width: 300px;
              font-size: 16px;
              font-weight: 500;
              color: #000;

              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;
            }

            .head-title:hover {
              color: #3d84ff;
            }
          }

          .conten-item-right-content {
            margin-top: 4px;
            font-size: 14px;
            letter-spacing: 1px;
            font-weight: 400;
            color: #666666;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 25px;
            cursor: pointer;
          }

          .conten-item-right-content:hover {
            color: #b29873;
          }
        }

        .head-time {
          // flex: 1;
          font-size: 12px;
          font-weight: 400;
          color: #999;
          // white-space: nowrap;
          text-align: right;
          margin-right: 15px;
          margin-top: 5px;
          overflow: hidden;
          width: 100%;
          color: #b29873;
          margin-bottom: 5px;
        }
      }
    }
  }

  .notice-right {
    // margin-left: 40px;

    .notice-right-top {
      // border: 1px solid red;
      // height: 130px;
      cursor: pointer;
    }

    .notice-right-bottom {
      // border: 1px solid red;
      height: 200px;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
</style>
